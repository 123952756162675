import React from 'react'
import useLocale from '@logdoc/l10n';
import LayoutDefault from "../layouts";
import * as classes from '../sass/style.module.scss'
import DocInfoCode from "../components/SyntaxHighlighter";
import { L10nProvider } from '@logdoc/l10n';


const Co = () => {
	return (
		<L10nProvider>
			<Config />
		</L10nProvider>
	)
}

export const Config = () => {
	const t = useLocale()
	return (
		<LayoutDefault>
			<section className={classes.sec}>
				<div className={classes.secBreadcrumbs}>
					<span>{t('config.breadcrumbs.1')}</span>
					<span>/</span>
					<span>{t('config.breadcrumbs.2')}</span>
				</div>
				<h2 className={classes.secTitle}>
					{t('config.title')}
				</h2>
				<div className={classes.secBlock}>
					<div className={classes.secDescription}>
						{t('config.description.p1')} <span className={classes.textOrange}>conf/application.conf</span>.
						<br/>
						<p>
						<em>{t('config.description.p2')}</em>
						</p>
					</div>
					<h5 className={classes.secSubtitleSecond}>
						{t('config.description.p3')}
					</h5>
					<div className={classes.secDescription}>
					<DocInfoCode size="big" text={`
logdoc { // ${t('config.doc.p1')}
  security { // ${t('config.doc.p2')}
    keys { // ${t('config.doc.p3')}
      private = "keys/private.der" // ${t('config.doc.p4')}
      public = "keys/public.der" // ${t('config.doc.p5')}
    }

    jwt { // ${t('config.doc.p6')}
      issuer = "ru.gang.logdoc" // ${t('config.doc.p7')}
      life_time = 10d // ${t('config.doc.p8')}
    }
  }

  plugins { // ${t('config.doc.p9')}
    sinks { // ${t('config.doc.p10')}
      jars_path = "/opt/logdoc/content/plugins/sink" // ${t('config.doc.p11')}

      enabled += org.logdoc.sinks.SyslogHandler // ${t('config.doc.p12')}
    }

    pipes { // ${t('config.doc.p13')}
      jars_path = "/opt/logdoc/content/plugins/pipe" // ${t('config.doc.p14')}

      enabled += org.logdoc.pipes.Telegramer

      org.logdoc.pipes.Telegramer.api_url = "https://api.telegram.org/botXXXXXX:YYYYYYYYY/sendMessage" // ${t('config.doc.p15')}
    }
  }
}

fair { // ${t('config.doc.p16')} https://github.com/LogDoc-org/fair-http-server/blob/main/src/main/resources/reference.conf
  http {
    port = 9000
    cors.expose = "Date"
    statics {
      root = ":classpath:/static"

      index_files = "index.html"
      map404_to = "/index.html"

      memory_cache {
        enabled = true
        max_file_size = 512kB
        lifetime = 3m
      }

      mime_types = [
        {
          mime = "image/svg+xml"
          ext = ".svg"
        }
        {
          mime = "font/woff2"
          ext = ".woff2"
        }
        {
          mime = "text/javascript"
          ext = ".js"
        }
      ]
    }
  }

  preload {
    load += ru.gang.logdoc.module.BatisModule
    load += ru.gang.logdoc.module.LinkingModule
    load += ru.gang.logdoc.dbready.DbChecker
  }
}

db { // ${t('config.doc.p17')}
  driver = org.postgresql.Driver
  url = "jdbc:postgresql://localhost:5432/logdoc"
  username = "logdoc"
  password = "logdoc"
}

clickhouse { // ${t('config.doc.p18')}
  url = "http://localhost:8253/"
  db = "logdoc"
  user = "logdoc"
  password = "logdoc"

  feed_threshold = 500000 // ${t('config.doc.p19')}
  auto_feed_timeout = 30 // ${t('config.doc.p20')}

  tables { // ${t('config.doc.p21')}
    logs_table = "logs_table" // ${t('config.doc.p22')}
    summing_table = "sums_table" // ${t('config.doc.p23')}
  }

  fields_uniquing { // ${t('config.doc.p24')}
    enabled = true // ${t('config.doc.p25')}

    name_accept_pattern = "^.*$" // ${t('config.doc.p26')}
    name_deny_pattern = "" // ${t('config.doc.p27')} 
  }
}
						`} language="HOCON" />
					</div>
				</div>
			</section>
		</LayoutDefault>
	);
};

export default Co;
